<template>
  <div v-resize="updateDimensions">
    <PluginDemo />
    <ShareButtons />
    <v-snackbar bottom v-model="privacyPolicyConcentPrompt" color="white">
      <span style="color: black">
        {{ $t("view.dataProcessingNotification") }}
        <router-link :to="{ name: 'Privacy policy' }">
          <a> {{ $t("view.learnMore..") }}</a>
        </router-link>
      </span>
      <template v-slot:action>
        <v-btn
          style="border-radius: 5px; font-weight: bold"
          dark
          color="#aa004c"
          elevation="0"
          @click="setPrivacyCookie"
        >
          {{ $t("view.accept") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-main
      style="padding: 56px 0px 0px"
      :style="'min-height: ' + this.minHeight + 'px;'"
    >
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </div>
</template>

<style>
.v-snack {
  z-index: 9999999!important;
}
</style>

<script>
import Vue from "vue";
import { store } from "../../store/index.js";

Vue.directive("resize", {
  inserted: function (el, binding) {
    const onResizeCallback = binding.value;
    window.addEventListener("resize", () => {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      onResizeCallback({ width, height });
    });
  },
});

export default {
  name: "BaseView",
  store,
  methods: {
    updateDimensions() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      this.reloadKey++;
    },
    handler: function handler(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    setPrivacyCookie() {
      var date = new Date();
      date.setSeconds(date.getSeconds() + 31556926);
      var dateString = date.toGMTString();
      var cookieString = "PRIVACY_CONCENT=true; expires=" + dateString;
      document.cookie = cookieString;
      this.privacyPolicyConcentPrompt = false;
    },
    getPrivacyCookie() {
      let cookies = document.cookie;
      if (cookies.includes("PRIVACY_CONCENT=true")) {
        this.privacyPolicyConcentPrompt = false;
      } else {
        this.privacyPolicyConcentPrompt = true;
      }
    },
  },
  mounted() {
    document.addEventListener("resize", this.updateDimensions());
    this.minHeight = window.innerHeight - 108; // 108 footer
    document.addEventListener("beforeunload", this.handler);
  },
  computed: {
    reloadKey: {
      get() {
        return this.$store.state.reloadKey;
      },
      set(newValue) {
        return this.$store.dispatch("setReloadKey", newValue);
      },
    },
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      },
    },
    screenHeight: {
      get() {
        return this.$store.state.screenHeight;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenHeight", newValue);
      },
    },
  },
  mounted() {
    this.getPrivacyCookie();
    /*Notification.requestPermission().then(function(result) {
      const title = "Actions Notification";
      const options = {
        actions: [
          {
            action: "coffee-action",
            title: "Coffee",
            icon: "/demos/notification-examples/images/action-1-128x128.png"
          },
          {
            action: "doughnut-action",
            title: "Doughnut",
            icon: "/demos/notification-examples/images/action-2-128x128.png"
          },
          {
            action: "gramophone-action",
            title: "gramophone",
            icon: "/demos/notification-examples/images/action-3-128x128.png"
          },
          {
            action: "atom-action",
            title: "Atom",
            icon: "/demos/notification-examples/images/action-4-128x128.png"
          }
        ]
      };

      const maxVisibleActions = Notification.maxActions;
      if (maxVisibleActions < 4) {
        options.body =
          `This notification will only display ` +
          `${maxVisibleActions} actions.`;
      } else {
        options.body =
          `This notification can display up to ` +
          `${maxVisibleActions} actions.`;
      }

      serviceWorkerRegistration.showNotification(title, options);
    });*/
  },
  data: () => ({
    privacyPolicyConcentPrompt: false,
    minHeight: 640,
  }),
  components: {
    PluginDemo: () => import("@/components/PluginDemo.vue"),
    ShareButtons: () => import("@/components/ShareButtons.vue"),
  },
  watch: {
    privacyPolicyConcentPrompt() {
      console.log("watch1");
      console.log(this.privacyPolicyConcentPrompt);
      let self = this;
      Vue.nextTick(function () {
        self.getPrivacyCookie();
      });
    },
  },
};
</script>
